import React, {useEffect} from 'react';

import {insertScript} from 'features/accessibility/libs/domManipulations/domManipulations';
import {sendTopStripMenuAnalytics} from '../../../../components/__helpers/header/topStripMenu/analytics/sendTopStripMenuAnalytics';

import s from './accessibilityButton.scss';

export const ACCESSIBILITY_SCRIPT_URL = 'https://acsbapp.com/apps/app/dist/js/app.js';
export const ACCESSIBILITY_CONFIG = {
    statementLink: '',
    feedbackLink: '',
    footerHtml: '',
    hideMobile: true,
    hideTrigger: true,
    disableBgProcess: false,
    language: 'en',
    position: 'right',
    leadColor: '#146ff8',
    triggerColor: '#146ff8',
    triggerRadius: '50%',
    triggerPositionX: 'right',
    triggerPositionY: 'bottom',
    triggerIcon: 'people',
    triggerSize: 'bottom',
    triggerOffsetX: 20,
    triggerOffsetY: 20,
    mobile: {
        triggerSize: 'small',
        triggerPositionX: 'right',
        triggerPositionY: 'bottom',
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        triggerRadius: '20',
    },
};

/**
 * Accessibility button
 *
 * @returns {React.ReactElement} react component
 */
export const AccessibilityButton: React.FC = () => {
    useEffect(() => {
        /* istanbul ignore next */
        insertScript(ACCESSIBILITY_SCRIPT_URL, () => {
            window.acsbJS.init(ACCESSIBILITY_CONFIG);
        });
    }, []);

    return (
        <button
            aria-label="Accessibility button"
            className={s.icon}
            data-acsb="trigger"
            id="ascbButton"
            onClick={() => sendTopStripMenuAnalytics('Accessibility')}
        >
            <img alt="Accessibility button" src={require('./svg/accessibility.svg')} />
        </button>
    );
};

export default AccessibilityButton;
