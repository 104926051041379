import React from 'react';
import {Link} from 'react-router-dom';
import {sendDesktopAnalyticsPerSession} from '@optimaxdev/analytics/desktop';

import {Icon} from 'components/__helpers/icon/icon';
import {LINK_PAGE, LINK_UHC_SUPPORT} from 'constants/links';
import {openChat} from 'libs/chat';

import {TopStripMenuDropdown} from '../topStripMenuDropdown/topStripMenuDropdown';
import {sendTopStripMenuAnalytics} from '../analytics/sendTopStripMenuAnalytics';
import s from './customerCare.scss';

export const CustomerCare = () => {
    return (
        <div
            className={s.customerCare}
            onMouseEnter={() =>
                sendDesktopAnalyticsPerSession({
                    event: {
                        name: 'General',
                        action: 'Customer care',
                        category: 'Header - D',
                        label: 'Open Menu',
                    },
                })
            }
        >
            <div className={s.titleContainer}>
                <div className={s.title}>Customer Care</div>
                <div className={s.arrowIcon}>
                    <Icon name="myAccountArrow" />
                </div>
            </div>
            <TopStripMenuDropdown activeClass={s.activeClass}>
                <ul>
                    <li>
                        <a
                            href={LINK_UHC_SUPPORT}
                            onClick={() => sendTopStripMenuAnalytics('Help Center')}
                        >
                            <Icon name="helpCenter" />
                            Help Center
                        </a>
                    </li>
                    <li>
                        <button
                            onClick={() => {
                                openChat();
                                sendTopStripMenuAnalytics('Chat with Us');
                            }}
                        >
                            <Icon name="chatWidthUs" />
                            Chat with Us
                        </button>
                    </li>
                    <li>
                        <Link
                            to={LINK_PAGE.CONTACT_US}
                            onClick={() => sendTopStripMenuAnalytics('Contact Us')}
                        >
                            <Icon name="contactUsCustomCare" />
                            Contact Us
                        </Link>
                    </li>
                </ul>
            </TopStripMenuDropdown>
        </div>
    );
};
