import React, {FC} from 'react';

import {Price} from 'components/__helpers/price/price';

import s from './planAllowanceRow.scss';

export type PropsType = {
    allowance: number;
    copay: number;
    frameTypeTitle?: string;
    isAllowanceContacts?: boolean;
    isFrameAllowanceZero?: boolean;
};

export const PlanAllowanceItem: FC<PropsType> = ({
    allowance,
    copay,
    frameTypeTitle = 'any frame',
    isAllowanceContacts = false,
    isFrameAllowanceZero = false,
}) => {
    return (
        <>
            {isAllowanceContacts && !isFrameAllowanceZero && (
                <p>
                    <span className={s.divider}>Or</span>
                </p>
            )}
            <p className={s.firstRow}>
                Up to{' '}
                <span className={s.price}>
                    <Price>{allowance}</Price>
                </span>
            </p>

            <p className={s.secondRow}>
                for {frameTypeTitle} (co-pay <Price>{copay}</Price>)
            </p>
        </>
    );
};
