import React, {FC} from 'react';
import classNames from 'classnames';

import {ALLOWANCE_TYPE} from 'constants/plan';
import {Price} from 'components/__helpers/price/price';
import {BenefitType} from 'libs/hooks/usePlanRequest';
import flags from 'constants/flags/flags';

import {PlanAllowanceTitle} from './planAllowanceTitle';
import {PlanAllowanceItem} from './planAllowanceItem';
import s from './planAllowanceRow.scss';

export type PropsType = {
    frameAllowance: BenefitType | null;
    frameAllowanceForCL?: BenefitType | null;
};
const getIsAllowanceContacts = (isUhcCcl, flatAllowance, frameAllowanceForCL): boolean =>
    isUhcCcl && !flatAllowance && frameAllowanceForCL && frameAllowanceForCL.allowance > 0;
export const PlanAllowanceRow: FC<PropsType> = ({frameAllowance, frameAllowanceForCL}) => {
    const isUHCCL = flags.dUHCCL.isEnabled();

    const flatAllowance = frameAllowance?.description === ALLOWANCE_TYPE.FLAT;
    const isAllowanceContacts = getIsAllowanceContacts(isUHCCL, flatAllowance, frameAllowanceForCL);
    return frameAllowance ? (
        <div className={s.wrapper}>
            <PlanAllowanceTitle
                flatAllowance={flatAllowance}
                frameAllowanceForCL={frameAllowanceForCL}
            />
            <div
                className={classNames(s.tableItem, {
                    [s.allowanceItem]: flatAllowance,
                    [s.itemContacts]: isAllowanceContacts,
                })}
            >
                {flatAllowance ? (
                    <>
                        <p className={s.flatRateTitle}>
                            {isUHCCL
                                ? 'Any frames, lenses or contact lenses'
                                : 'Any frame or lenses'}
                        </p>
                        <p className={s.flatRatePrice}>
                            <Price>{frameAllowance.allowance}</Price>
                        </p>
                    </>
                ) : (
                    Boolean(frameAllowance?.allowance) && (
                        <PlanAllowanceItem
                            copay={frameAllowance.copay}
                            allowance={frameAllowance.allowance}
                        />
                    )
                )}
            </div>
            {isUHCCL && Boolean(frameAllowanceForCL?.allowance) && (
                <div
                    className={classNames(s.tableItem, {
                        [s.itemContactsWithDivider]: isAllowanceContacts,
                    })}
                >
                    {frameAllowanceForCL && (
                        <PlanAllowanceItem
                            isAllowanceContacts={isAllowanceContacts}
                            copay={frameAllowanceForCL.copay}
                            allowance={frameAllowanceForCL.allowance}
                            frameTypeTitle="contact lenses"
                            isFrameAllowanceZero={frameAllowance.allowance === 0}
                        />
                    )}
                </div>
            )}
        </div>
    ) : null;
};
